<template>
  <div>
    <Header 
      button="Adicionar Categoria"
      title="Gerenciamento de Categorias"
      subtitle="Gerencie seus produtos e sua exposição."
      @click="$modal.show('categorie')"
    />
    <div v-if="categories.length" class="max-w-7xl mx-auto mt-3 px-4 sm:px-6 lg:px-8">
      <div class="bg-white shadow overflow-hidden rounded-md">
        <ul class="divide-y divide-gray-200">
          <li v-for="(item, index) in categories" :key="index">
            <div class="flex items-center justify-between px-4 py-4 sm:px-6 hover:bg-gray-50">
              <div class="min-w-0 flex-1">
                <p class="font-medium text-sm text-primary truncate"># {{ item.name }}</p>
                <div class="hidden sm:flex sm:items-center text-xs font-medium text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 mr-1" viewBox="0 0 20 20" fill="currentColor"><path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" /></svg>
                  <span>Produtos: {{ item.products }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 ml-3 mr-1" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clip-rule="evenodd" /></svg>
                  <span>FAQ: {{ item.faqs }}</span>
                </div> 
              </div>
              <button @click="$modal.show('edit'), temp = { ...item, index: index }" type="button" class="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>
              </button>
              <button @click="$modal.show('confirm'), temp = item" type="button" class="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <t-modal name="categorie" class="z-45">
      <p class="text-base font-medium text-gray-800">Nova Categoria</p>
      <form @submit.prevent class="mt-4">
        <div class="grid grid-cols-2 gap-2">
          <div class="col-span-2">
            <label for="name" class="block text-sm font-medium text-gray-700">Nome</label>
            <input v-model="name" type="text" name="street" id="street" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
        <button @click="saveCategorie()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Salvar
        </button>
      </form>
    </t-modal> 
    <t-modal name="edit" class="z-45">
      <p class="text-base font-medium text-gray-800">Editar Categoria</p>
      <form @submit.prevent class="mt-4">
        <div class="grid grid-cols-2 gap-2">
          <div class="col-span-2">
            <label for="name" class="block text-sm font-medium text-gray-700">Nome</label>
            <input v-model="temp.name" type="text" name="street" id="street" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
        <button @click="editCategorie()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Salvar
        </button>
      </form>
    </t-modal> 
    <t-modal name="confirm" class="z-45">
      <p class="text-base font-medium text-gray-800">Apagar Categoria</p>
      <form @submit.prevent class="mt-8 text-center">
        <p class="font-medium">Tem certeza que deseja excluir essa categoria?</p>
        <p class="text-gray-600 text-sm mb-4">Os anúncios perderão referência e as faqs excluídas.</p>
        <p class="text-primary text-xs mb-4">Dica: Ao excluir, reorganize seus produtos para melhor experiência do usuário.</p>
        <button @click="deleteCategorie()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Apagar
        </button>
      </form>
    </t-modal> 
  </div>
</template>

<script>
import { faqsColl, firebase, listingsColl, productsColl } from '@/firebase'

export default {
  components: { Header: () => import('@/components/layout/header') },

  data() { return { categories: [], name: '', temp: {} } },

  mounted() { this.getCategories() },
  
  methods: {
    async getCategories() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Categories').get()
      .then((result) => { this.categories = result.data().available })
      .catch(() => { this.$toast.error('Falha ao obter as categorias, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async saveCategorie() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      if (this.name.length === 0) {
        this.$store.commit('setLoading', false)
        this.$toast.error('Preencha o nome da categoria')
        return
      }
      if (this.name.includes('/')) {
        this.$store.commit('setLoading', false)
        this.$toast.error('Caracter inválido, tente novamente')
        return
      }
      await listingsColl.doc('Categories').update({ available: firebase.firestore.FieldValue.arrayUnion({ name: this.name, products: 0, faqs: 0 }) })
      .then(() => { this.$toast.success('Categoria adicionada com sucesso'), this.getCategories() })
      .catch(() => { this.$toast.error('Falha ao salvar categoria, tente novamente') })
      await listingsColl.doc('Search').update({ categories: firebase.firestore.FieldValue.arrayUnion(this.name) })
      this.$modal.hide('categorie')
      this.name = ''
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async editCategorie() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      if (this.temp.name.length === 0) {
        this.$store.commit('setLoading', false)
        this.$toast.error('Preencha o nome da categoria')
        return
      }
      if (this.temp.name.includes('/')) {
        this.$store.commit('setLoading', false)
        this.$toast.error('Caracter inválido, tente novamente')
        return
      }
      const oldName = this.categories[this.temp.index].name
      this.categories[this.temp.index].name = this.temp.name
      
      let products = []
      await listingsColl.doc('Products').get().then(result => products = result.data().available).catch(() => { this.$toast.error('Falha ao editar categoria, tente novamente'), this.$store.commit('setLoading', false); return })
      await listingsColl.doc('Products').update({ available: products.map(item => item.categorie === oldName ? { ...item, categorie: this.temp.name } : item) })
      
      let productsSpecific = []
      await productsColl.where('categorie', '==', oldName).get().then(result => { console.log(result), productsSpecific = result.docs}).catch(() => { this.$toast.error('Falha ao editar categoria, tente novamente'), this.$store.commit('setLoading', false); return })
      const promisses = productsSpecific.map(async item => await productsColl.doc(item.id).update({ categorie: this.temp.name }))

      await Promise.all(promisses)

      await listingsColl.doc('Categories').update({ available: this.categories })
      .then(() => { this.$toast.success('Categoria editada com sucesso'), this.getCategories() })
      .catch(() => { this.$toast.error('Falha ao editar categoria, tente novamente') })
      this.$modal.hide('edit')
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async deleteCategorie() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Categories').update({ available: firebase.firestore.FieldValue.arrayRemove(this.temp) })
      .then(() => { this.$toast.success('Categoria excluída com sucesso'), this.getCategories() })
      .catch(() => { this.$toast.error('Falha ao excluir categoria, tente novamente') })
      await listingsColl.doc('Search').update({ categories: firebase.firestore.FieldValue.arrayRemove(this.temp.name) })
      await faqsColl.doc(this.temp.name).delete()
      this.$modal.hide('confirm')
      this.temp = {}
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  }  
}
</script>